<template>
    <div>
        <div class="declare_project clearfix">
            <div class="form_div">
                <div class="Addme" id="Addme">
                    <el-button size="mini" type="primary" @click="vieSch()" v-show="this.teamList.length<5">添加成员</el-button>
                    <el-button size="mini" type="info" v-show="this.teamList.length>=5">添加成员</el-button>
                </div>
            </div>
            <div class="form_div">
                <el-table 
                    :data="teamList" 
                    style="width: 100%;min-height:200px"
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="name" label="姓名" width="80"></el-table-column>
                    <el-table-column prop="snumber" label="学号"></el-table-column>
                    <el-table-column prop="grade" label="年级"></el-table-column>
                    <el-table-column prop="rank" label="类型">
                        <template slot-scope="scope">
                            <span>{{scope.row.rank==0?'项目负责人':'项目成员'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deptName" label="学校"></el-table-column>
                    <el-table-column prop="yname" label="所在院系/专业"></el-table-column>
                    <el-table-column prop="phone" label="联系电话"></el-table-column>
                    <el-table-column prop="email" label="E-mail"></el-table-column>
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <el-button @click="deleted(scope.row)" size="mini" type="danger">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="next_step">
                <el-button type="primary" @click="previous()">上一步</el-button>
                <el-button type="primary" @click="next()">下一步</el-button>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="add_Popup">
            <el-dialog
                title="添加成员"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="name" label="姓名" style="width:100%">
                        <el-input v-model="Form.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item prop="rank" label="类型" style="width:100%">
                        <el-select v-model="Form.rank" placeholder="请选择类型" @change="typefzr(Form.rank)">
                            <el-option label="项目负责人" value="0"></el-option>
                            <el-option label="项目成员" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="academyId" label="院系" style="width:100%">
                        <el-select v-model="Form.academyId" placeholder="请选择院系" :disabled="artments" @change="typeyx(Form.academyId)">
                            <el-option 
                            v-for="(item,index) in departList"
                            :key="index"
                            :label="item.yuanName" 
                            :value="item.yuanId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="snumber" label="学号" style="width:100%">
                        <el-input v-model="Form.snumber" placeholder="请输入学号"></el-input>
                    </el-form-item>
                    <el-form-item prop="grade" label="年级" style="width:100%">
                        <el-select v-model="Form.grade" placeholder="请选择年级" style="margin-right:10px" :disabled='disabled'>
                            <el-option
                                v-for="item in listYear"
                                :key="item.value"
                                :label="item.value + '年'"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="schoolName" label="学校名称" style="width:100%">
                        <el-input v-model="Form.schoolName" placeholder="请输入学校名称" disabled></el-input>
                    </el-form-item>
                    <el-form-item prop="phone" label="联系电话" style="width:100%">
                        <el-input v-model="Form.phone" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                    <el-form-item prop="email" label="E-mail" style="width:100%">
                        <el-input v-model="Form.email" placeholder="请输入E-mail邮箱"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="Addmember('Form')">确 定</el-button>
                    <el-button size="small" @click="closei()">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            teamList:[],
            departList:[],
            value:'',
            disabled:true,
            artments:true,
            listYear:[],
            dialogVisible:false,
            academyId:'',
            Form:{
                name:'',
                rank:'',
                snumber:'',
                grade:'',
                academyId:'',
                phone:'',
                email:'',
            },
            rules:{
                name:[
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                rank:[
                    { required: true, message: '请选择类型', trigger: 'blur' }
                ],
                snumber:[
                    { required: true, message: '请输入学号', trigger: 'blur' }
                ],
                grade:[
                    { required: true, message: '请选择年级', trigger: 'change' }
                ],
                academyId:[
                    { required: true, message: '请选择院系', trigger: 'blur' }
                ],
                phone:[
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    {
                        pattern: /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/,
                        message: "请输入正确的联系方式",
                    },
                ],
                email:[
                    { required: true, message: '请输入E-mail', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的E-mail地址', trigger: ['blur', 'change'] }
                ],
            },
        }
    },
    methods: {
        getapp(){
            if(this.$store.state.itemId!=null || this.$store.state.itemId!=''){
                this.axios.getInfo({
                    params:{
                        uid:this.$store.state.uid,
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        this.teamList = res.data.data.student
                    }else{
                        this.teamList = []
                    }
                }).catch(err=>{

                })
            }else{
                return false
            }
        },
        //查询学校
        vieSch(){
            this.axios.studentuid({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.dialogVisible = true
                    this.Form.schoolId = res.data.data.school.schoolId
                    this.Form.schoolName = res.data.data.school.schoolName
                    this.departList = res.data.data.yuanxi
                }
            }).catch(err=>{

            })
        },
        //选择负责人类型
        typefzr(val){
            this.artments = false
            if(this.Form.academyId!=''){
                this.Form.academyId = ''
            }
        },
        //选择院系
        typeyx(val){
            this.disabled = false
            if(this.Form.grade!=''){
                this.Form.grade = ''
            }
            if(this.Form.rank==0){
                let year = new Date().getFullYear() -1
                    this.listYear = [];
                if(val==5003 || val==5009 || val==5010 || val==5005){
                    for(let i=0;i<3;i++){
                        this.listYear.push({value:(year - i),label:(year - i)+ "年"});
                    }
                }else{
                    for(let i=0;i<2;i++){
                        this.listYear.push({value:(year - i),label:(year - i)+ "年"});
                    }
                }
                
            }else{
                let year = new Date().getFullYear() -1
                    this.listYear = [];
                if(val==5003){
                    for(let i=0;i<5;i++){   
                        this.listYear.push({value:(year - i),label:(year - i)+ "年"});
                    }
                }else{
                    for(let i=0;i<4;i++){
                        this.listYear.push({value:(year - i),label:(year - i)+ "年"});
                    }
                }
            }
        },
        //添加成员
        Addmember(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Form.itmId = this.$store.state.itemId
                    this.axios.addDcStudent(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //选择年级
        yearChange(val){
            this.Form.grade = val
        },
        //删除成员
        deleted(rows){
            this.axios.deleteStudent({
                params:{
                    sId:rows.sId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.getapp()
                }
            }).catch(err=>{

            })
        },
        // 上一步
        previous(){
            this.$router.push('/Projectinfor')
        },
        // 下一步
        next(){
            this.$router.push('/Newteacher')
        },
        handleClose(done){
            console.log(done)
        },
        closei(){
            this.dialogVisible =false
        },
    },
    mounted () {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Projectapplication.css';
</style>
<style>
.add_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
}
.add_Popup .el-dialog__title{
    color: #fff;
}
.add_Popup .el-input__inner{
    width: 300px;
}
.add_Popup .el-dialog__footer{
    text-align: center;
}
.add_Popup .el-textarea__inner{
    resize: none;
}
</style>